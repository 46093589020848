<template>
  <T3Renderer />
</template>

<script lang="ts" setup>
// Component to render content elements loop
import type { ConcreteComponent } from 'vue'
import { h } from 'vue'
import type { T3CeBaseProps, T3ContentElement } from '@t3headless/nuxt-typo3'

const props = withDefaults(
  defineProps<{
    /**
     * Array of content elements - colPos[x] from contentData
     */
    content?: T3ContentElement<T3CeBaseProps>[]
    /**
     * Control frame component displaying
     */
    frame?: boolean
  }>(),
  {
    content: () => [],
    frame: true,
  },
)

// render standalone component
function renderComponent(element: T3ContentElement<T3CeBaseProps>, index: number) {
  const { id, type, appearance, content } = element
  const component = useT3DynamicCe(type) as ConcreteComponent
  return h(component, {
    uid: id,
    appearance,
    index,
    id: appearance.frameClass === 'none' ? `c${id}` : null,
    ...content,
  })
}

// render component with frame wrapper
function renderFrame(element: T3ContentElement<T3CeBaseProps>, index: number) {
  const component = useT3DynamicComponent({
    prefix: 'T3',
    type: 'Frame',
    mode: '',
  })
  return h(
    component,
    {
      ...element.appearance,
      id: `c${element.id}`,
      type: element.type,
    },
    () => renderComponent(element, index),
  )
}

// render loop of content elements
function T3Renderer() {
  return props.content.map((element, index: number) => {
    return props.frame && element.appearance.frameClass !== 'none'
      ? renderFrame(element, index)
      : renderComponent(element, index)
  })
}
</script>
